import { ShoppingCartIcon } from "@heroicons/react/solid";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { BasketEntry } from "@web/common";
import { SidebarProductBox } from "@web/common/components/SidebarProductBox";
import { Label, RegularButton } from "@web/ui";
import { formatMoney } from "@web/utils";

import { RoutesConfig } from "src/config/routes";
import useBasket from "src/hooks/useBasket";

export const SidebarBasket = () => {
  const navigate = useNavigate();
  const { pushSkuToBasket, lineItems, totalQuantity, grandTotal } = useBasket();
  const sortedLineItems = useMemo(() => [...lineItems].reverse(), [lineItems]);

  return (
    <div className="float-right fixed z-0 border-1 top-0 right-0 bg-neutral_0 w-[20%] mr-[15px] h-full">
      <div className="flex flex-col justify-between">
        <div className="border-b-1 w-full text-center flex flex-col justify-end h-[170px] pb-2">
          <Label size="100">Your Basket</Label>
          <div className="inline-block mt-6 w-full">
            {lineItems.length > 0 && (
              <Label size="200" className="ml-2 float-left">{`Total: ${formatMoney(
                grandTotal
              )}`}</Label>
            )}
            <Label size="200" color="text-textIcon-blackSecondary" className="pr-2 float-right">
              {totalQuantity !== 1
                ? `${lineItems.length} Line items`
                : `${lineItems.length} Line item`}
            </Label>
          </div>
        </div>

        <div className="overflow-auto bg-neutral_100 h-[calc(100vh-170px-75px)] -mb-[1px]">
          {lineItems.length > 0 ? (
            <div>
              {sortedLineItems.map((product: BasketEntry, index: number) => (
                <SidebarProductBox
                  addToBasket={(sku, qty) => pushSkuToBasket(sku, qty)}
                  key={product.sku.id}
                  product={product}
                  quantity={product.quantity}
                  index={index}
                />
              ))}
            </div>
          ) : (
            <div className="mt-10 text-center">
              <Label size="200" data-testid="empty-sidebar-text">
                Your basket is empty
              </Label>
            </div>
          )}
        </div>
        <div className="relative px-4 border-t-1 bg-neutral_0 z-20 h-[75px] flex flex-col justify-center">
          <RegularButton
            variant="primary"
            size="large"
            width="container"
            label="View Basket"
            LeadingIcon={ShoppingCartIcon}
            onClick={() => {
              navigate(RoutesConfig.basket);
            }}
            data-testid="viewBasketButton"
          />
        </div>
      </div>
    </div>
  );
};
