import { memo, useCallback } from "react";

import {
  LocalStocktakeReportOldExtraItemForm,
  StocktakeReportItemMeasurementUnitFieldPath,
  StocktakeReportItemQuantityFieldPath,
  StocktakeReportItemRobValueAmountFieldPath,
  StocktakeReportItemSingleUnitGrossPriceFieldPath,
} from "src/models";

import { StocktakeExtraProductTileDetails } from "../StocktakeExtraProductTileDetails";
import {
  EntityQuantityWarning,
  FormErrors,
  QuantityField,
  RobDetails,
  RobValueDetails,
  StocktakeFormProductTile,
} from "../StocktakeFormProductTile";
import { useUpdateDependentFormFields } from "../StocktakeFormProductTile/useUpdateDependentFormFields";

type Props = {
  product: LocalStocktakeReportOldExtraItemForm;
  hasLineNumberPlaceholder?: boolean;
  indexInCollection: number;
};

export const StocktakeFormOldExtraProductTile = memo(({ product, indexInCollection }: Props) => {
  const quantityFieldPath: StocktakeReportItemQuantityFieldPath = `oldExtraItems.${indexInCollection}.quantity`;
  const singleUnitGrossPriceFieldPath: StocktakeReportItemSingleUnitGrossPriceFieldPath = `oldExtraItems.${indexInCollection}.singleUnitGrossPrice.amount`;
  const measurementUnitFieldPath: StocktakeReportItemMeasurementUnitFieldPath = `oldExtraItems.${indexInCollection}.measurementUnit`;
  const robValueAmountFieldPath: StocktakeReportItemRobValueAmountFieldPath = `oldExtraItems.${indexInCollection}.robValue.amount`;
  const itemsFieldPath = "oldExtraItems";

  // All extra items have `salesEntityQuantity` equal to `1`
  const salesEntityQuantity = 1;

  useUpdateDependentFormFields({
    initialQuantity: product.quantity,
    initialSingleUnitGrossPriceAmount: product.singleUnitGrossPrice.amount,
    initialMeasurementUnit: product.measurementUnit,
    salesEntityQuantity,
    currencyCode: product.robValue.currencyCode,
    quantityFieldPath,
    singleUnitGrossPriceFieldPath,
    measurementUnitFieldPath,
    robValueAmountFieldPath,
  });

  const renderProductDetails = useCallback(
    (product: LocalStocktakeReportOldExtraItemForm, className: string) => (
      <StocktakeExtraProductTileDetails product={product} className={className} />
    ),
    []
  );

  const renderQuantityField = useCallback(
    () => (
      <QuantityField
        initialQuantity={product.quantity}
        initialSingleUnitGrossPriceAmount={product.singleUnitGrossPrice.amount}
        initialMeasurementUnit={product.measurementUnit}
        salesEntityQuantity={salesEntityQuantity}
        currencyCode={product.robValue.currencyCode}
        quantityFieldPath={quantityFieldPath}
        singleUnitGrossPriceFieldPath={singleUnitGrossPriceFieldPath}
        measurementUnitFieldPath={measurementUnitFieldPath}
      />
    ),
    [
      product.quantity,
      product.singleUnitGrossPrice.amount,
      product.measurementUnit,
      product.robValue.currencyCode,
      quantityFieldPath,
      singleUnitGrossPriceFieldPath,
      measurementUnitFieldPath,
    ]
  );

  const renderEntityQuantityWarning = useCallback(
    () => (
      <EntityQuantityWarning
        initialQuantity={product.quantity}
        initialSingleUnitGrossPriceAmount={product.singleUnitGrossPrice.amount}
        initialMeasurementUnit={product.measurementUnit}
        salesEntityQuantity={salesEntityQuantity}
        currencyCode={product.robValue.currencyCode}
        entityQuantityOnStock={product.entityQuantityOnStock}
        quantityFieldPath={quantityFieldPath}
        singleUnitGrossPriceFieldPath={singleUnitGrossPriceFieldPath}
        measurementUnitFieldPath={measurementUnitFieldPath}
      />
    ),
    [
      product.quantity,
      product.singleUnitGrossPrice.amount,
      product.measurementUnit,
      product.robValue.currencyCode,
      product.entityQuantityOnStock,
      quantityFieldPath,
      singleUnitGrossPriceFieldPath,
      measurementUnitFieldPath,
    ]
  );

  const renderRobDetails = useCallback(
    () => (
      <RobDetails
        initialQuantity={product.quantity}
        initialSingleUnitGrossPriceAmount={product.singleUnitGrossPrice.amount}
        initialMeasurementUnit={product.measurementUnit}
        salesEntityQuantity={salesEntityQuantity}
        currencyCode={product.robValue.currencyCode}
        quantityFieldPath={quantityFieldPath}
        singleUnitGrossPriceFieldPath={singleUnitGrossPriceFieldPath}
        measurementUnitFieldPath={measurementUnitFieldPath}
      />
    ),
    [
      product.quantity,
      product.singleUnitGrossPrice.amount,
      product.measurementUnit,
      product.robValue.currencyCode,
      quantityFieldPath,
      singleUnitGrossPriceFieldPath,
      measurementUnitFieldPath,
    ]
  );

  const renderRobValueDetails = useCallback(
    () => (
      <RobValueDetails
        initialQuantity={product.quantity}
        initialSingleUnitGrossPriceAmount={product.singleUnitGrossPrice.amount}
        initialMeasurementUnit={product.measurementUnit}
        salesEntityQuantity={salesEntityQuantity}
        currencyCode={product.robValue.currencyCode}
        quantityFieldPath={quantityFieldPath}
        singleUnitGrossPriceFieldPath={singleUnitGrossPriceFieldPath}
        measurementUnitFieldPath={measurementUnitFieldPath}
      />
    ),
    [
      product.quantity,
      product.singleUnitGrossPrice.amount,
      product.measurementUnit,
      product.robValue.currencyCode,
      quantityFieldPath,
      singleUnitGrossPriceFieldPath,
      measurementUnitFieldPath,
    ]
  );

  const renderFormErrors = useCallback(
    () => <FormErrors indexInCollection={indexInCollection} itemsFieldPath={itemsFieldPath} />,
    [indexInCollection]
  );

  return (
    <StocktakeFormProductTile
      product={product}
      hasLineNumberPlaceholder={true}
      renderProductDetails={renderProductDetails}
      renderQuantityField={renderQuantityField}
      salesEntityQuantity={salesEntityQuantity}
      measurementUnit={product.measurementUnit}
      renderEntityQuantityWarning={renderEntityQuantityWarning}
      renderRobDetails={renderRobDetails}
      renderRobValueDetails={renderRobValueDetails}
      renderFormErrors={renderFormErrors}
    />
  );
});
StocktakeFormOldExtraProductTile.displayName = "StocktakeFormOldExtraProductTile";
