import { z } from "zod";

import { ApiMoneySchema } from "./ApiMoneySchema";
import { LiteProductSkuSchema } from "./LiteProductSkuSchema";

export const LitePurchaseOrderItemSchema = z.object({
  variantId: z.string().optional(),
  name: z.string(),
  measurementUnit: z.string(),
  singleGrossPrice: ApiMoneySchema,
  quantity: z.number(),
  entityQuantity: z.number(),
  supplierItemId: z.string().optional(),
  impaCode: z.string().optional(),
  lineNumber: z.number().optional(),
  skuDetails: LiteProductSkuSchema.optional(),
});
