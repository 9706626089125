import { useIsMutating } from "@tanstack/react-query";
import { useFlag } from "@unleash/proxy-client-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ProductSku } from "@web/common";
import { SupplierSubBasket } from "@web/common/views";
import { ActionBar, Heading, Label, Paragraph, containerPadding } from "@web/ui";
import { formatMoney } from "@web/utils";

import { OrderDraftSavedInfo } from "src/components/OrderDraftInfo";
import RequestProductInline from "src/components/RequestProductInline";
import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { LEGACY_useCreateOrderDraftMutation } from "src/hooks/LEGACY_useCreateOrderDraftMutation";
import { LEGACY_useOrderDraft } from "src/hooks/LEGACY_useOrderDraft";
import { useCreateOrderDraftMutation } from "src/hooks/orderDrafts/useCreateOrderDraftMutation";
import { usePrepareOrderDraft } from "src/hooks/orderDrafts/usePrepareOrderDraft";
import useBasket from "src/hooks/useBasket";
import { useNetworkDependentAction } from "src/hooks/useNetworkDependentAction";
import { useOfflineDraftEnabled } from "src/hooks/useOfflineDraftEnabled";
import { usePunchoutOrder } from "src/hooks/usePunchoutOrder";
import { useShareBasketMutation } from "src/hooks/useShareBasketMutation";
import { useToastMessage } from "src/hooks/useToastMessage";

import {
  BasketEmpty,
  BasketFormModalController,
  BasketHeader,
  LineItemFastGather,
  RfqItem,
} from "./components";

export const Basket: React.FC = () => {
  const navigate = useNavigate();
  const [{ configuration, isPunchoutSession, forcePreconfigureOrderSetup, orderType }] =
    useAppStateContext();

  useEffect(() => {
    if (forcePreconfigureOrderSetup) {
      navigate(RoutesConfig.gatherSetup);
    }
  }, [forcePreconfigureOrderSetup, navigate]);

  const {
    lineItems,
    lineItemsBySupplier,
    pushSkuToBasket,
    rfqItems,
    draft,
    setNewDraft,
    grandTotal,
    lastUpdated: basketLastUpdated,
  } = useBasket();
  const hasMultiSupplierFeature = useFlag("multi-supplier");
  const hasRfqFeature = useFlag("rfq");

  const [isLoading, setIsLoading] = useState(false);
  const [isBasketFormModalOpen, setIsBasketFormModalOpen] = useState(false);
  const { setToastMessage } = useToastMessage();
  const [{ port }] = useAppStateContext();
  const { getOrderItems } = useBasket();
  const { allowOnlineOnly, AllowOnlineOnlyWarningModal } = useNetworkDependentAction();

  const isBasketEmpty = lineItems.length === 0 && rfqItems.length === 0;
  const setQuantity = useCallback(
    (sku: ProductSku) => (newQuantity: number) => pushSkuToBasket(sku, newQuantity),
    [pushSkuToBasket]
  );
  const isFromDraft = Boolean(draft);
  const isBasketFormRequired = Boolean(configuration?.basketForm);
  const numberOfItems = lineItems.length + rfqItems.length;

  const { isOfflineDraftEnabled } = useOfflineDraftEnabled();

  const isCreatingOnlineDraft = !!useIsMutating({
    mutationKey: ["LEGACY_createDraft"],
  });
  const isUpdatingOnlineDraft = !!useIsMutating({
    mutationKey: ["LEGACY_updateDraft"],
  });

  const isCreatingOfflineDraft = !!useIsMutating({
    mutationKey: ["createDraft"],
  });
  const isUpdatingOfflineDraft = !!useIsMutating({
    mutationKey: ["updateDraft"],
  });

  const isCreatingDraft = isOfflineDraftEnabled ? isCreatingOfflineDraft : isCreatingOnlineDraft;
  const isUpdatingDraft = isOfflineDraftEnabled ? isUpdatingOfflineDraft : isUpdatingOnlineDraft;

  const openBasketFormModal = () => setIsBasketFormModalOpen(true);
  const closeBasketFormModal = () => setIsBasketFormModalOpen(false);

  const createOrder = () => {
    navigate(`${RoutesConfig.orderInfo}${isFromDraft ? "?from=draft" : ""}`);
  };
  const createRequisition = () => {
    isBasketFormRequired
      ? openBasketFormModal()
      : navigate(`${RoutesConfig.requisitionInfo}${isFromDraft ? "?from=draft" : ""}`);
  };

  const postForm = (path: string, params: Record<string, string>, method = "post") => {
    const form = document.createElement("form");
    form.method = method;
    form.action = path;

    for (const key in params) {
      if (key in params) {
        const hiddenField = document.createElement("input");
        hiddenField.type = "hidden";
        hiddenField.name = "data";
        hiddenField.value = params[key];

        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);
    form.submit();
  };

  const catalogItems = lineItems.map((lineItem) => ({
    variantId: lineItem.sku.id,
    quantity: lineItem.quantity,
  }));

  const sendOrderMessage = usePunchoutOrder({ catalogItems });

  const handleSendOrderMessage = async () => {
    setIsLoading(true);
    const response = await sendOrderMessage();
    postForm(response.url, { value: response.message });
  };

  const handleCreateDraftSuccess = ({
    draftId,
    updatedAt,
  }: {
    draftId: string;
    updatedAt: string;
  }) => {
    setNewDraft({ draftId, updatedAt });
  };

  const { getOrderDraftData: getDraftData } = LEGACY_useOrderDraft();
  const { mutate } = LEGACY_useCreateOrderDraftMutation((orderRequisition) =>
    handleCreateDraftSuccess({
      draftId: orderRequisition.id,
      updatedAt: orderRequisition.updatedAt,
    })
  );

  const { getPartialOrderDraftData } = usePrepareOrderDraft();
  const { mutate: createDraft } = useCreateOrderDraftMutation({
    onSuccess: (createdDraft) =>
      handleCreateDraftSuccess({ draftId: createdDraft.id, updatedAt: createdDraft.updatedAt }),
    hasSuccessMessage: true,
    hasErrorMessage: true,
  });

  const { mutateAsync: createDraftAsync } = useCreateOrderDraftMutation({
    onSuccess: (createdDraft) =>
      handleCreateDraftSuccess({ draftId: createdDraft.id, updatedAt: createdDraft.updatedAt }),
  });

  const handleCreatingDraft = useMemo(() => {
    return () => {
      // TODO #5641: Remove setting draft here along with Online Draft logic
      // Related to #8117: Dirty fix to prevent overwriting the draft after it was cleared in some edge cases.
      // Dirty fix because online draft is a legacy feature and Offline Draft is not susceptible to this problem.
      setNewDraft({ draftId: "", updatedAt: new Date().toISOString(), status: "CREATION_STARTED" });

      if (isOfflineDraftEnabled) {
        const draftToCreate = getPartialOrderDraftData();
        createDraft(draftToCreate);
        return;
      }

      mutate(getDraftData());
    };
  }, [
    setNewDraft,
    isOfflineDraftEnabled,
    mutate,
    getDraftData,
    getPartialOrderDraftData,
    createDraft,
  ]);

  const { mutate: shareBasket, isPending: isSharingBasket } = useShareBasketMutation({
    onSuccess: () => {
      setToastMessage({
        type: "success",
        message:
          "Success! Your basket has been sent to your email and is ready to be shared with your colleagues.",
      });
    },
    onError: () => {
      setToastMessage({
        type: "failure",
        message: "This did not work. Please try sharing the basket via email again.",
      });
    },
  });

  const { mutate: shareBasketAfterDraftCreation, isPending: isSharingBasketAfterDraftCreation } =
    useShareBasketMutation({
      onSuccess: () => {
        setToastMessage({
          type: "success",
          message:
            "Success! A draft has been created and your basket has been sent to your email and is ready to be shared with your colleagues.",
        });
      },
      onError: () => {
        setToastMessage({
          type: "failure",
          message:
            "A draft has been created, but we failed to share your basket. Please try again and if that does not work, please contact our Customer Support team.",
        });
      },
    });

  const handleShareBasket = useCallback(() => {
    if (isSharingBasket || isSharingBasketAfterDraftCreation) {
      return;
    }

    if (!port) {
      throw new Error("No port selected");
    }

    allowOnlineOnly(async () => {
      if (!draft) {
        const draftToCreate = getPartialOrderDraftData();
        try {
          // Keep it here for compatiblity reason.
          // TODO #5641: Remove this action and fall back to `setDraft` everywhere when removing Online Draft logic
          setNewDraft({
            draftId: "",
            updatedAt: new Date().toISOString(),
            status: "CREATION_STARTED",
          });
          await createDraftAsync(draftToCreate);
          shareBasketAfterDraftCreation({
            portId: port.id,
            items: getOrderItems(),
            orderType,
          });
        } catch (err) {
          // Error handling is happening in `onError` in respective hooks, just log error here
          console.error(err);
        }
      } else {
        shareBasket({
          portId: port.id,
          items: getOrderItems(),
          orderType,
        });
      }
    });
  }, [
    isSharingBasket,
    isSharingBasketAfterDraftCreation,
    port,
    allowOnlineOnly,
    draft,
    getPartialOrderDraftData,
    setNewDraft,
    createDraftAsync,
    shareBasketAfterDraftCreation,
    getOrderItems,
    orderType,
    shareBasket,
  ]);

  let actionBarPrimaryButtonSettings;
  if (isPunchoutSession) {
    actionBarPrimaryButtonSettings = {
      title: "Send to FMS",
      onClick: handleSendOrderMessage,
    };
  } else if (configuration?.canCreateOrder) {
    if (configuration.isAutoApprovedRequisition) {
      actionBarPrimaryButtonSettings = {
        title: "Go To Checkout",
        onClick: createRequisition,
        testId: "createRequisitionButton",
      };
    } else {
      actionBarPrimaryButtonSettings = {
        title: "Go To Checkout",
        onClick: createOrder,
        testId: "goToCheckoutButton",
      };
    }
  } else if (configuration?.canCreateRequisition) {
    actionBarPrimaryButtonSettings = {
      title: "Create Requisition",
      onClick: createRequisition,
      testId: "createRequisitionButton",
    };
  }

  const actionBarHelperButtonsSettings = useMemo(
    () => [
      ...((!draft || draft.status === "CREATION_STARTED") && !isPunchoutSession
        ? [
            {
              title: "Save As Draft",
              onClick: handleCreatingDraft,
              testId: "saveDraftButton",
              isLoading: isCreatingDraft || isUpdatingDraft,
            },
          ]
        : []),
      ...(!isBasketEmpty && isOfflineDraftEnabled && !isPunchoutSession
        ? [
            {
              title: "Share As Email",
              onClick: handleShareBasket,
              isLoading: isSharingBasket || isSharingBasketAfterDraftCreation,
              testId: "shareBasketButton",
            },
          ]
        : []),
    ],
    [
      draft,
      isPunchoutSession,
      handleCreatingDraft,
      isCreatingDraft,
      isUpdatingDraft,
      isBasketEmpty,
      isOfflineDraftEnabled,
      handleShareBasket,
      isSharingBasket,
      isSharingBasketAfterDraftCreation,
    ]
  );

  return (
    <>
      <BasketHeader />
      <AllowOnlineOnlyWarningModal />
      <div className="w-full min-h-screen bg-neutral_100">
        {!isLoading && numberOfItems > 0 && (
          <ActionBar
            primaryButtonSettings={actionBarPrimaryButtonSettings}
            helperButtons={actionBarHelperButtonsSettings}
            backButtonSettings={{
              title: "Continue Shopping",
              onClick: () => {
                navigate(RoutesConfig.gatherSetup);
              },
            }}
          />
        )}
        <div className={`pt-5 ${containerPadding}`}>
          <hr className="mb-2" />
          <div className="py-6 flex justify-between items-center">
            <Heading size="100">Basket</Heading>
            {numberOfItems > 0 && (
              <div className="flex justify-between">
                <div className="flex">
                  {draft && draft.status !== "CREATION_STARTED" && (
                    <OrderDraftSavedInfo
                      draftLastUpdated={draft.updatedAt}
                      isSavingDraft={isCreatingDraft || isUpdatingDraft}
                      basketLastUpdated={basketLastUpdated}
                    />
                  )}
                </div>
              </div>
            )}
            <div>
              {numberOfItems > 0 && (
                <div className="ml-auto">
                  <div className="items-center flex flex-row">
                    <Paragraph size="100">Total:</Paragraph>
                    <div className="pl-2">
                      <Heading size="200">{formatMoney(grandTotal)}</Heading>
                    </div>
                  </div>
                  <div className="flex flex-col text-right">
                    <Paragraph size="100">{`${numberOfItems} Item${
                      numberOfItems > 1 ? "s" : ""
                    }`}</Paragraph>
                  </div>
                </div>
              )}
            </div>
          </div>
          {configuration?.basketForm && (
            <BasketFormModalController
              isBasketFormModalOpen={isBasketFormModalOpen}
              isFromDraft={isFromDraft}
              basketForm={configuration.basketForm}
              onCloseModal={closeBasketFormModal}
            />
          )}
          <hr className="mb-6" />
          <div className="flex justify-center">
            {!isBasketEmpty ? (
              <div data-testid="basket-container" className="w-full">
                {hasRfqFeature && (
                  <Label size="300" className="text-text-whiteDisabled block mt-5 mb-0">
                    CATALOG ITEMS
                  </Label>
                )}
                {hasMultiSupplierFeature
                  ? Object.entries(lineItemsBySupplier).map(([supplierId, items]) => {
                      return (
                        <SupplierSubBasket
                          key={supplierId}
                          lineItems={items}
                          onOrderCommentDelete={() => {
                            /* TODO #3462 */
                          }}
                          onOrderCommentSave={() => {
                            /* TODO #3462 */
                          }}
                          setQuantity={setQuantity}
                          supplierId={supplierId}
                          supplierDisplayName={configuration?.suppliers[supplierId].name}
                        />
                      );
                    })
                  : lineItems.map((lineItem, index) => (
                      <LineItemFastGather lineItem={lineItem} index={index} key={lineItem.sku.id} />
                    ))}
                {hasRfqFeature &&
                  configuration?.fleet.permissions.includes("CREATE_REQUISITION") &&
                  configuration.fleet.allow.createRfq && (
                    <>
                      <Label size="300" className="text-text-whiteDisabled block mt-5 mb-0">
                        REQUESTED ITEMS
                      </Label>
                      {rfqItems.map((rfqItem) => {
                        return <RfqItem rfqItem={rfqItem} key={rfqItem.id} />;
                      })}
                      <RequestProductInline basket />
                    </>
                  )}
              </div>
            ) : (
              <BasketEmpty />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
