import { useCallback, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { OrderInfo, OrderInfoFormValues } from "@web/common/pages";
import { ActionBar, containerPadding } from "@web/ui";

import { AttentionInfo } from "src/components/AttentionInfo";
import { TopBarController } from "src/components/TopBar";
import { RoutesConfig } from "src/config/routes";
import AppStateContext from "src/contexts/AppStateContext";
import useBasket from "src/hooks/useBasket";
import { useBasketVerification } from "src/hooks/useBasketVerification";
import { useCreateOrderMutation } from "src/hooks/useCreateOrderMutation";
import { useNetworkDependentAction } from "src/hooks/useNetworkDependentAction";
import { useOfflineDraftEnabled } from "src/hooks/useOfflineDraftEnabled";
import { useProductVariantsMutation } from "src/hooks/useProductVariantsMutation";
import { useToastMessage } from "src/hooks/useToastMessage";
import AppState from "src/state/models";
import { LiteOrderCreationRequest } from "src/typegens";

export const PurchaseOrderSummary = () => {
  const [appState] = useContext(AppStateContext);
  const { draft, getOrderItems, grandTotal, lineItems } = useBasket();
  const { setToastMessage } = useToastMessage();
  const vessel = (appState as Required<AppState>).configuration.vessel;
  const orderTypes = appState.configuration?.orderTypes;
  const port = appState.port;
  const isCustomerOrderIdRequired = !appState.configuration?.fleet.allow.customerOrderIdOptional;
  const orderType = appState.orderType;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isFromDraft = searchParams.get("from") === "draft";
  const navigate = useNavigate();
  const { mutate, isPending } = useCreateOrderMutation(isFromDraft ? draft?.id : undefined);
  const { mutateAsync: productVariantsAsyncMutation, isPending: isProductVariantsMutationPending } =
    useProductVariantsMutation();
  const { findChangedProducts, BasketVerificationModal } = useBasketVerification();
  // This is just a simplification, because Lite App does not support multiple suppliers.
  const supplierId = lineItems[0]?.sku.supplier?.supplierId;
  const { allowOnlineOnly, AllowOnlineOnlyWarningModal } = useNetworkDependentAction();
  const { isOfflineDraftEnabled } = useOfflineDraftEnabled();

  const submitOrder = async (orderSummary: OrderInfoFormValues) => {
    if (!appState.configuration) {
      return setToastMessage({
        type: "failure",
        message: "No configuration, please try refreshing the page",
      });
    }
    if (!port) {
      return setToastMessage({
        type: "failure",
        message: "No port selected",
      });
    }
    if (!orderType) {
      return setToastMessage({
        type: "failure",
        message: "No order type selected",
      });
    }

    // If the order contains only RFQs and no Catalog Items, then the submission
    // will fail because of how the supplierId is acquired, and the user
    // won't get any feedback whatsoever.
    if (!supplierId) {
      return setToastMessage({
        type: "failure",
        message: "Unknown supplier",
      });
    }

    const draftId = isFromDraft && !isOfflineDraftEnabled ? draft?.id : undefined;

    const request: LiteOrderCreationRequest = {
      supplierId,
      ...(orderSummary.dutyFreeDeclaration
        ? {
            dutyFreeDeclaration: {
              dutyFree: orderSummary.dutyFreeDeclaration.dutyFree,
              name: orderSummary.dutyFreeDeclaration.name,
              position: orderSummary.dutyFreeDeclaration.position,
            },
          }
        : {}),
      agentInformation: orderSummary.agentInformation,
      consolidated: orderSummary.consolidated ?? false,
      customerOrderId: orderSummary.customerOrderId,
      deliveryDate: orderSummary.deliveryDate ?? "",
      draftId: draftId,
      invoiceAccountId: orderSummary.invoiceAccountId,
      items: getOrderItems(),
      orderNotes: orderSummary.orderNotes,
      portId: port.id,
      requesterInformation: orderSummary.requesterInformation ?? {
        name: "",
        email: "",
      },
      storageLabel: orderSummary.storageLabel,
      vesselId: vessel.id,
      warehouseId: orderSummary.warehouseId,
      orderType,
    };

    const skus = await productVariantsAsyncMutation({
      variantIds: lineItems.map((item) => item.sku.id),
      portId: port.id,
      orderType: orderType,
    });

    const { productsWithChangedPrice, unavailableProducts } = findChangedProducts({
      lineItems,
      skus,
      submitCallback: () => {
        mutate(request);
      },
    });

    if (unavailableProducts.length > 0 || productsWithChangedPrice.length > 0) {
      return;
    }

    mutate(request);
  };

  const submitOrderHandler = (orderSummary: OrderInfoFormValues) => {
    allowOnlineOnly(() => {
      submitOrder(orderSummary);
    });
  };

  const renderAttentionInfo = useCallback(() => {
    const items = port?.attentionInfo || [];
    return items.length > 0 ? <AttentionInfo items={items} className="mt-4.5 mb-3" /> : null;
  }, [port]);

  return (
    <>
      <TopBarController />

      <div className="flex-grow bg-neutral_100">
        <ActionBar
          backButtonSettings={{
            title: "Return to Basket",
            onClick: () => {
              navigate(RoutesConfig.basket);
            },
          }}
        />
        <div className={`${containerPadding} flex py-3`}>
          <>
            <OrderInfo
              creationMode="ORDER_CREATION"
              grandTotal={grandTotal}
              invoiceAccounts={appState.configuration?.vessel.invoiceAccounts}
              warehouses={appState.configuration?.warehouses}
              deliveryDate={appState.deliveryDate}
              loading={isPending || isProductVariantsMutationPending}
              nrLineItems={lineItems.length}
              port={port}
              submitForm={submitOrderHandler}
              vessel={vessel}
              dutyFreeDeclaration={appState.dutyFreeDeclaration}
              isCustomerOrderIdRequired={isCustomerOrderIdRequired}
              withOrderComment
              renderAttentionInfo={renderAttentionInfo}
              orderType={orderType}
              orderTypes={orderTypes}
            />
            <AllowOnlineOnlyWarningModal />
            <BasketVerificationModal />
          </>
        </div>
      </div>
    </>
  );
};
