import { z } from "zod";

import { LiteAttachmentSchema } from "./LiteAttachmentSchema";

export const LiteAttentionInfoFleetsConfigEntrySchema = z.object({
  attentionInfoType: z.literal("DELIVERY_CHARGES"),
  portLocationCodes: z.array(z.string()),
  fileMetadata: LiteAttachmentSchema.optional(),
  imageMetadata: LiteAttachmentSchema.optional(),
});
