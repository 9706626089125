import { z } from "zod";

import { assertTypeEquality } from "@web/utils";

import { LiteOrderRequisition } from "src/typegens";

import { ApiMoneySchema } from "./ApiMoneySchema";
import { LiteAgentInformationSchema } from "./LiteAgentInformationSchema";
import { LiteAttachmentSchema } from "./LiteAttachmentSchema";
import { LiteCommentSchema } from "./LiteCommentSchema";
import { LiteDutyFreeDeclarationSchema } from "./LiteDutyFreeDeclarationSchema";
import { LiteInvoiceAccountSchema } from "./LiteInvoiceAccountSchema";
import { LiteOrderRequisitionHistorySchema } from "./LiteOrderRequisitionHistorySchema";
import { LitePersonalInformationSchema } from "./LitePersonalInformationSchema";
import { LitePortSchema } from "./LitePortSchema";
import { LitePriceModifierInformationSchema } from "./LitePriceModifierInformationSchema";
import { LiteProductChangeSchema } from "./LiteProductChangeSchema";
import { LitePurchaseOrderItemSchema } from "./LitePurchaseOrderItemSchema";
import { LiteRfqItemSchema } from "./LiteRfqItemSchema";
import { LiteSupplierInformationSchema } from "./LiteSupplierInformationSchema";
import { LiteVesselSchema } from "./LiteVesselSchema";
import { LiteWarehouseSchema } from "./LiteWarehouseSchema";
import { OrderStatusSchema } from "./OrderStatusSchema";
import { OrderStocktakeTypeSchema } from "./OrderStocktakeTypeSchema";
import { OrderTypeSchema } from "./OrderTypeSchema";

export const LiteOrderRequisitionSchema = z.object({
  id: z.string(),
  customerOrderId: z.string().optional(),
  status: OrderStatusSchema,
  type: OrderTypeSchema,
  deliveryDate: z.string().optional(),
  orderNotes: z.string().optional(),
  rejectionNotes: z.string().optional(),
  port: LitePortSchema,
  vessel: LiteVesselSchema,
  totalGrossAmount: ApiMoneySchema.optional(),
  history: z.array(LiteOrderRequisitionHistorySchema),
  requesterInformation: LitePersonalInformationSchema,
  agentInformation: LiteAgentInformationSchema.optional(),
  catalogItems: z.array(LitePurchaseOrderItemSchema),
  rfqItems: z.array(LiteRfqItemSchema).optional(),
  changes: z.array(LiteProductChangeSchema),
  quotationDate: z.string().optional(),
  requestDate: z.string().optional(),
  orderDate: z.string().optional(),
  createdAt: z.string(),
  updatedAt: z.string(),
  invoiceAccount: LiteInvoiceAccountSchema.optional(),
  storageLabel: z.string().optional(),
  consolidated: z.boolean(),
  warehouse: LiteWarehouseSchema.optional(),
  comments: z.array(LiteCommentSchema),
  createdByExternalSystem: z.boolean(),
  attachments: z.array(LiteAttachmentSchema),
  priceModifiers: z.array(LitePriceModifierInformationSchema),
  dutyFreeDeclaration: LiteDutyFreeDeclarationSchema.optional(),
  closed: z.boolean(),
  supplierInformation: LiteSupplierInformationSchema.optional(),
  orderType: OrderStocktakeTypeSchema,
});

export type LocalLiteOrderRequisition = z.infer<typeof LiteOrderRequisitionSchema>;

assertTypeEquality<LocalLiteOrderRequisition, LiteOrderRequisition, LocalLiteOrderRequisition>();
