import { QueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import { isParsingError, isStorageError } from "@web/utils";

import { isApiError, isOfflineCatalogError } from "src/utils";

import { useOfflineCapabilities } from "../OfflineCapabilities";

type Props = {
  render: (queryClient: QueryClient) => React.ReactNode;
};

const shouldQueryUseErrorBoundary = (error: unknown) =>
  (isApiError(error) && error.request.method === "GET") ||
  (isStorageError(error) && error.request.access === "read") ||
  isParsingError(error) ||
  isOfflineCatalogError(error);

const shouldMutationUseErrorBoundary = (error: unknown) => isParsingError(error);

export const QueryClientCreator = ({ render }: Props) => {
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();

  const queryClient = useMemo(
    () =>
      new QueryClient(
        areOfflineCapabilitiesEnabled
          ? {
              defaultOptions: {
                queries: {
                  networkMode: "always",
                  refetchOnWindowFocus: false,
                  refetchOnReconnect: false,
                  retry: 1,
                  throwOnError: shouldQueryUseErrorBoundary,
                },
                mutations: {
                  throwOnError: shouldMutationUseErrorBoundary,
                },
              },
            }
          : {
              defaultOptions: {
                queries: {
                  throwOnError: shouldQueryUseErrorBoundary,
                },
                mutations: {
                  throwOnError: shouldMutationUseErrorBoundary,
                },
              },
            }
      ),
    [areOfflineCapabilitiesEnabled]
  );

  return <>{render(queryClient)}</>;
};
