import { XIcon } from "@heroicons/react/solid";
import { useFlag } from "@unleash/proxy-client-react";
import classNames from "classnames";

import { getCareAndUsesRows } from "@web/common/components/ProductDetails";
import {
  Description,
  Row,
  RowGroup,
} from "@web/common/components/ProductDetails/SkuAttributesUtils";
import { attachmentTypeToAttachmentNameMap } from "@web/common/utils";
import { DigitalAssetsWrapper, Heading, Paragraph } from "@web/ui";
import { formatDate, formatNumber, imagor, isDefined } from "@web/utils";

import { LocalStocktakeService } from "src/services/LocalStocktakeService";
import { LiteProductSku, LiteProductSupplierInformation } from "src/typegens";

import ChilledIcon from "../../icons/Chilled.svg";
import HalalIcon from "../../icons/Halal.svg";
import VeganIcon from "../../icons/Vegan.svg";
import VegetarianIcon from "../../icons/Vegetarian.svg";
import FrozenIcon from "../../icons/frozen.svg";
import KosherIcon from "../../icons/kosher.svg";
import { ProductDetailsModalSidebar } from "./ProductDetailsModalSidebar";

interface Props {
  closeModal: () => void;
  quantity?: number;
  addToBasket?: (sku: LiteProductSku, quantity: number) => void;
  productSku: LiteProductSku;
  supplierInformation?: LiteProductSupplierInformation;
  productName?: string;
  maxLength?: number;
  withSidebar?: boolean;
}

export const ProductDetailsModal = ({
  closeModal,
  quantity,
  addToBasket,
  maxLength = 4,
  withSidebar,
  productSku,
  productName,
  supplierInformation,
}: Props) => {
  const areDigitalAssetsEnabled = useFlag("digital-asset");

  const canSidebarBeRendered = withSidebar && isDefined(addToBasket) && isDefined(quantity);
  const isCategoryUnspecified = (category: string) => category === "NOT_DEFINED";

  const formatNetContent = (netContent?: string) => {
    if (!netContent) {
      return undefined;
    }

    const [numericPart, unit] = netContent.split(/\s+/);

    if (isNaN(Number(numericPart))) {
      return undefined;
    }

    const roundedNumber = formatNumber(Number(numericPart));
    return `${roundedNumber} ${unit}`;
  };

  return (
    <div
      className={classNames(
        "inline-block align-bottom bg-neutral_0 rounded-3xl text-left shadow-xl transform transition-all sm:my-8 sm:align-middle min-h-[80vh] max-h-[80vh] sm:w-full",
        withSidebar ? "min-w-[800px] max-w-[60vw]" : "min-w-[600px] max-w-[40vw]"
      )}
    >
      {!withSidebar && (
        <div className="flex justify-end pr-4 pt-4 absolute top-0 right-0">
          <button
            type="button"
            className="rounded-md text-text-whiteDisabled hover:text-textIcon-blackSecondary focus:outline-none ml-auto"
            onClick={closeModal}
          >
            <span className="sr-only">Close modal</span>
            <XIcon className="h-5 w-5 text-text-whiteDisabled" aria-hidden="true" />
          </button>
        </div>
      )}

      <div className={classNames("flex overflow-x-hidden", withSidebar ? "w-[70%]" : "w-full")}>
        <div className="p-7 overflow-scroll max-h-[80vh] w-full overflow-x-hidden overflow-y-auto">
          <div className="flex space-x-4">
            <img
              alt="product image"
              src={productSku.images[0] && imagor(productSku.images[0].imageUrl)}
              width={256}
              height={256}
              className="border-1 rounded-2xl mb-4 flex-shrink-0"
            />

            <div>
              {!!supplierInformation?.name && (
                <Paragraph size="100" color="text-textIcon-blackSecondary" className="font-medium">
                  Supplied by{" "}
                  <span className="text-primaryDefault">{supplierInformation.name}</span>
                </Paragraph>
              )}

              <Heading size="200" className="font-bold mb-2">
                {productName || productSku.about?.name}
              </Heading>
              <div className="flex space-x-3 mb-2">
                {productSku.careAndUse?.isVegan && (
                  <img src={VeganIcon} alt="vegan icon" className="w-7 stroke-slate-900" />
                )}
                {productSku.careAndUse?.isVegetarian && (
                  <img
                    src={VegetarianIcon}
                    alt="vegetarian icon"
                    className="w-7 stroke-slate-900"
                  />
                )}
                {productSku.careAndUse?.isKosher && (
                  <img src={KosherIcon} alt="kosher icon" className="w-7 stroke-slate-900" />
                )}
                {productSku.careAndUse?.isHalal && (
                  <img src={HalalIcon} alt="halal icon" className="w-7 stroke-slate-900" />
                )}
                {productSku.storage?.storageInformation === "Frozen" && (
                  <img src={FrozenIcon} alt="frozen icon" className="w-7 stroke-slate-900" />
                )}
                {productSku.storage?.storageInformation === "Chilled" && (
                  <img src={ChilledIcon} alt="chilled icon" className="w-7 stroke-slate-900" />
                )}
              </div>
              <div className="flex space-x-2">
                <div className="bg-neutral_200 rounded-lg my-1 px-2 py-1 flex flex-row w-fit">
                  <Paragraph size="200" className="font-medium">
                    {LocalStocktakeService.formatEntityQuantity(productSku.salesEntityQuantity)}{" "}
                    {productSku.measurementUnit}
                  </Paragraph>
                </div>
                {productSku.about?.generalInformation?.packingQuantity && (
                  <div className="bg-neutral_200 rounded-lg my-1 px-2 py-1 flex flex-row w-fit">
                    <Paragraph size="200" className="font-medium">
                      Quantity per item:{" "}
                      {LocalStocktakeService.formatEntityQuantity(
                        productSku.about?.generalInformation?.packingQuantity
                      )}
                    </Paragraph>
                  </div>
                )}
                {productSku.about?.quality?.brand && (
                  <div className="bg-neutral_200 rounded-lg my-1 px-2 py-1 flex flex-row w-fit ">
                    <Paragraph size="200" className="text-primaryDefault font-medium">
                      {productSku.about.quality.brand}
                    </Paragraph>
                  </div>
                )}
              </div>
            </div>
          </div>
          {!!productSku.about && (
            <div className="ml-4">
              {!!productSku.about?.description && (
                <Heading size="200" className="font-semibold mb-3 mt-2">
                  Description
                </Heading>
              )}
              <Paragraph size="100" className="whitespace-pre-line">
                {productSku.about?.description}
              </Paragraph>
              {areDigitalAssetsEnabled && productSku.attachments.length > 0 && (
                <>
                  <Heading size="300" className="font-semibold mb-3 mt-4">
                    Downloads
                  </Heading>
                  <DigitalAssetsWrapper
                    assets={productSku.attachments.map((attachment) => ({
                      name: attachment.name || "",
                      size: attachment.fileSize,
                      downloadLink: attachment.attachmentUrl,
                      attachmentId: attachment.attachmentUrl,
                      category: isCategoryUnspecified(attachment.type)
                        ? ""
                        : attachmentTypeToAttachmentNameMap.get(attachment.type) || "",
                      createdDate: formatDate(attachment.createdDate),
                    }))}
                    showMoreLabel={`Show More Downloads (${productSku.attachments.length - 2})`}
                    showLessLabel="Show Less Downloads"
                    showLimit={2}
                    className="mt-2 mb-3"
                  />
                </>
              )}
              <RowGroup
                rows={{
                  "Size Imperial": productSku.dimensionsAndDelivery?.dimensions?.sizeImperial,
                  "Size Metric": productSku.dimensionsAndDelivery?.dimensions?.sizeMetric,
                  "Net Content": formatNetContent(
                    productSku.dimensionsAndDelivery?.dimensions?.netContent
                  ),
                }}
              />
              <div>
                {productSku.careAndUse && (
                  <RowGroup title="Care & use" rows={getCareAndUsesRows(productSku.careAndUse)} />
                )}

                <RowGroup
                  title="Nutrition"
                  rows={{
                    "Per Serving Or Per 100g": productSku.careAndUse?.nutrition.perServing,
                    "Serving Size": productSku.careAndUse?.nutrition.servingSize,
                    calories: productSku.careAndUse?.nutrition.calories,
                    protein: productSku.careAndUse?.nutrition.protein,
                    fat: productSku.careAndUse?.nutrition.fat,
                    "Saturated Fat": productSku.careAndUse?.nutrition.saturatedFat,
                    Carbohydrates: productSku.careAndUse?.nutrition.carbohydrates,
                    sugar: productSku.careAndUse?.nutrition.sugar,
                    cholesterol: productSku.careAndUse?.nutrition.cholesterol,
                    sodium: productSku.careAndUse?.nutrition.sodium,
                    salt: productSku.careAndUse?.nutrition.salt,
                    fiber: productSku.careAndUse?.nutrition.fiber,
                  }}
                />
                <Description label="Ingredients" value={productSku.careAndUse?.ingredients} />
              </div>
              <RowGroup
                title="General information"
                rows={{
                  "Quantity per product":
                    productSku.about?.generalInformation?.packingQuantity &&
                    productSku.about?.generalInformation?.packingQuantity !== 1
                      ? LocalStocktakeService.formatEntityQuantity(
                          productSku.about?.generalInformation?.packingQuantity
                        )
                      : undefined,
                  brand: productSku.about?.quality?.brand,
                  "IMPA code": productSku.about?.generalInformation?.impaCode,
                  "Dangerous goods code": productSku.about?.generalInformation?.dangerousGoods,
                  "Inventory Hazardous Material Code":
                    productSku.about?.generalInformation?.hazardousMaterial,
                  "Country of Origin": productSku.about?.quality?.countryOrigin,
                  "Supplier article code": supplierInformation?.supplierItemId,
                  "Buyer article code": productSku.buyerItemId,
                  "Customs code": productSku.customsCode,
                  gtin: productSku.about?.generalInformation?.gtin,
                  manufacturer: productSku.manufacture?.manufacturerName,
                  "Manufacturer Part Number": productSku.manufacture?.manufacturersPartNumber,
                }}
              />
              <div>
                <Description
                  label="Storage Information"
                  value={productSku.storage?.storageInformation}
                />
                <Row label="Minimum shelf life (in days)" value={productSku.storage?.shelfLife} />
              </div>
            </div>
          )}
        </div>
        {canSidebarBeRendered && (
          <ProductDetailsModalSidebar
            productSku={productSku}
            quantity={quantity}
            addToBasket={addToBasket}
            closeModal={closeModal}
            maxLength={maxLength}
          />
        )}
      </div>
    </div>
  );
};
