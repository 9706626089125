import { KeyboardEvent, memo, useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { preprocessFormAmountValue } from "@web/utils";

import {
  LocalStocktakeReportForm,
  StocktakeReportItemMeasurementUnitFieldPath,
  StocktakeReportItemQuantityFieldPath,
  StocktakeReportItemSingleUnitGrossPriceFieldPath,
} from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";

import { AmountField } from "../StocktakeFormProductTile/AmountField";
import { useItemFormDetails } from "../StocktakeFormProductTile/useItemFormDetails";

type Props = {
  initialQuantity: number | null | undefined;
  initialSingleUnitGrossPriceAmount: number | null | undefined;
  initialMeasurementUnit: string;
  salesEntityQuantity: number;
  currencyCode: string;
  quantityFieldPath: StocktakeReportItemQuantityFieldPath;
  singleUnitGrossPriceFieldPath: StocktakeReportItemSingleUnitGrossPriceFieldPath;
  measurementUnitFieldPath: StocktakeReportItemMeasurementUnitFieldPath;
};

export const SingleUnitGrossPriceField = memo(
  ({
    initialQuantity,
    initialSingleUnitGrossPriceAmount,
    initialMeasurementUnit,
    salesEntityQuantity,
    currencyCode,
    quantityFieldPath,
    singleUnitGrossPriceFieldPath,
    measurementUnitFieldPath,
  }: Props) => {
    const { control, setValue } = useFormContext<LocalStocktakeReportForm>();

    const { modifiedSingleUnitGrossPriceAmount } = useItemFormDetails({
      initialQuantity,
      initialSingleUnitGrossPriceAmount,
      initialMeasurementUnit,
      salesEntityQuantity,
      currencyCode,
      quantityFieldPath,
      singleUnitGrossPriceFieldPath,
      measurementUnitFieldPath,
    });

    const calculateNewSingleUnitGrossPrice = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) =>
        LocalStocktakeService.getNewSingleUnitGrossPriceFromKeyboardEvent(
          preprocessFormAmountValue(modifiedSingleUnitGrossPriceAmount) || 0,
          event
        ),
      [modifiedSingleUnitGrossPriceAmount]
    );

    const setFieldValue = useCallback(
      (newSingleUnitGrossPriceAmountValue: number | undefined) => {
        setValue(singleUnitGrossPriceFieldPath, newSingleUnitGrossPriceAmountValue);
      },
      [setValue, singleUnitGrossPriceFieldPath]
    );

    return (
      <Controller
        name={singleUnitGrossPriceFieldPath}
        control={control}
        render={({ field }) => (
          <AmountField
            {...field}
            className="px-2 text-right"
            aria-label="Price per unit form field (optional)"
            calculateNewValue={calculateNewSingleUnitGrossPrice}
            setNewValue={setFieldValue}
            suffix={` ${currencyCode}`}
            placeholder={`0.00 ${currencyCode}`}
          />
        )}
      />
    );
  }
);
SingleUnitGrossPriceField.displayName = "SingleUnitGrossPriceField";
