import { ArrowLeftIcon, XIcon } from "@heroicons/react/solid";
import { useFlag } from "@unleash/proxy-client-react";
import classnames from "classnames";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { IntersectionMonitor } from "@web/common";
import { useSearchInput } from "@web/common/hooks/useSearchHelpers";
import { ComboButton, Heading, Label, LinkButton, LoadingInline, Paragraph } from "@web/ui";

import EmptyIndicator from "src/components/EmptyIndicator";
import { ProductBox } from "src/components/ProductBox";
import RequestProductInline from "src/components/RequestProductInline";
import { SidebarBasket } from "src/components/SidebarBasket";
import { RoutesConfig, getCategoryByIdPath } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import useBasket from "src/hooks/useBasket";
import { useSearch } from "src/hooks/useSearch";
import BaseLayout from "src/layouts/Base";
import { LiteCategory, LiteProduct } from "src/typegens";

// TODO #11728: Remove mocks after integration with API is done
const mockCategoryResults: LiteCategory[] = Array.from(Array(32).keys()).map((index) => ({
  id: `cat-${index}`,
  name: `Category ${index}`,
  children: [],
  productsNumber: 1,
}));

const Loading = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const { isOnline } = useNetworkDetector();

  return (
    <div className={classnames(className, "w-full m-auto flex items-center justify-center gap-2")}>
      {areOfflineCapabilitiesEnabled && !isOnline ? (
        <Paragraph size="200">Switch to online mode to get the search results</Paragraph>
      ) : (
        <>
          <LoadingInline />
          <Paragraph size="200">{t("pages.search.loading")}</Paragraph>
        </>
      )}
    </div>
  );
};

export const Search = () => {
  const { t } = useTranslation();
  const hasRfqFeature = useFlag("rfq");
  const hasGatherExpNavigationFeature = useFlag("gather-exp-navigation");
  const hasImpaCategorySearchFeature = useFlag("impa-category-search");
  const [{ configuration, lastGatherExpCategoryId }] = useAppStateContext();
  const { query: infiniteQuery } = useSearch();
  const { isPending, data, hasNextPage, fetchNextPage, isFetchingNextPage } = infiniteQuery;
  const { query } = useSearchInput();
  const navigate = useNavigate();
  const isEmptyQuery = query === "";

  const { pushSkuToBasket, getQuantity } = useBasket();

  useEffect(() => {
    if (isEmptyQuery) {
      navigate(RoutesConfig.order.overview);
    }
  }, [isEmptyQuery, navigate, query]);

  const onCloseSearchHandler = useCallback(() => {
    if (lastGatherExpCategoryId && hasGatherExpNavigationFeature) {
      const encodedLastGatherExpCategoryId = encodeURIComponent(lastGatherExpCategoryId);
      navigate(getCategoryByIdPath(encodedLastGatherExpCategoryId));
    } else {
      navigate(RoutesConfig.gatherSetup);
    }
  }, [hasGatherExpNavigationFeature, lastGatherExpCategoryId, navigate]);

  const onCategoryResultClick = useCallback(
    (categoryId: string) => {
      navigate(getCategoryByIdPath(categoryId));
    },
    [navigate]
  );

  if (isEmptyQuery) {
    return null;
  }

  const productResults = data?.pages.flatMap((p) => p.items) || [];
  const hasAnyProductResults = productResults.length > 0;

  // TODO #11728: Integrate with API to get category results
  const categoryResults: LiteCategory[] = mockCategoryResults;
  const hasAnyCategoryResults = categoryResults.length > 0;

  return (
    <div className="w-[100vw] h-[100vh] bg-neutral_100">
      <BaseLayout>
        <div className="flex flex-col flex-grow">
          <div className="flex">
            <div className="container">
              <div className="grid grid-cols-1 w-10/12 pr-8 pl-4 ml-10 mt-4 mb-4">
                <ComboButton
                  label="Close search"
                  iconPlacement="leading"
                  Icon={hasImpaCategorySearchFeature ? ArrowLeftIcon : XIcon}
                  onClick={onCloseSearchHandler}
                />

                {hasImpaCategorySearchFeature && hasAnyCategoryResults && (
                  <div className="flex flex-wrap items-center gap-2 mt-4">
                    <Label size="300">
                      {t("pages.search.tryCategory", { count: categoryResults.length })}
                    </Label>
                    {categoryResults.map((category) => (
                      <LinkButton
                        key={category.id}
                        label={category.name}
                        size="small"
                        variant="primary"
                        onClick={() => onCategoryResultClick(category.id)}
                      />
                    ))}
                  </div>
                )}

                <Heading
                  className={classnames(
                    hasImpaCategorySearchFeature && hasAnyCategoryResults ? "mt-6" : "mt-4"
                  )}
                  size="300"
                >
                  Search results for &quot;{query}&quot;
                </Heading>
                {isPending && <Loading className="mt-7" />}
                {!isPending && hasAnyProductResults && (
                  <div className="flex flex-col gap-2 mt-2">
                    {productResults.map((product: LiteProduct, index: number) => {
                      return (
                        <ProductBox
                          addToBasket={(sku, qty) => pushSkuToBasket(sku, qty)}
                          key={product.id}
                          product={product}
                          quantity={getQuantity(product.skuList[0])}
                          index={index}
                        />
                      );
                    })}
                    {hasRfqFeature &&
                      configuration?.fleet.permissions.includes("CREATE_REQUISITION") &&
                      configuration.fleet.allow.createRfq && (
                        <div className="w-full my-4">
                          <RequestProductInline />
                        </div>
                      )}
                    {hasNextPage && (
                      <IntersectionMonitor onEnter={fetchNextPage}>
                        <div className="h-10 flex items-center">
                          {isFetchingNextPage && <Loading />}
                        </div>
                      </IntersectionMonitor>
                    )}
                  </div>
                )}
                {!isPending && !hasAnyProductResults && (
                  <div className="mt-7">
                    <EmptyIndicator
                      title={t("pages.search.noResults")}
                      subtitle={t("pages.search.couldntFind")}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <SidebarBasket />
      </BaseLayout>
    </div>
  );
};
