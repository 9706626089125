import { z } from "zod";

import { LiteImageSchema } from "./LiteImageSchema";
import { LiteInventorySchema } from "./LiteInventorySchema";
import { LitePriceSchema } from "./LitePriceSchema";
import { LiteSkuAboutSchema } from "./LiteSkuAboutSchema";
import { LiteSkuAttachmentSchema } from "./LiteSkuAttachmentSchema";
import { LiteSkuCareAndUseSchema } from "./LiteSkuCareAndUseSchema";
import { LiteSkuDimensionsAndDeliverySchema } from "./LiteSkuDimensionsAndDeliverySchema";
import { LiteSkuManufactureSchema } from "./LiteSkuManufactureSchema";
import { LiteSkuStorageInformationSchema } from "./LiteSkuStorageInformationSchema";
import { LiteSkuSupplierInformationSchema } from "./LiteSkuSupplierInformationSchema";
import { PriceTypeSchema } from "./PriceTypeSchema";

export const LiteProductSkuSchema = z.object({
  id: z.string(),
  productId: z.string(),
  measurementUnit: z.string(),
  images: z.array(LiteImageSchema),
  price: LitePriceSchema,
  priceType: PriceTypeSchema.optional(),
  salesEntityPrice: LitePriceSchema,
  salesEntityQuantity: z.number(),
  inventory: LiteInventorySchema.optional(),
  about: LiteSkuAboutSchema.optional(),
  dimensionsAndDelivery: LiteSkuDimensionsAndDeliverySchema.optional(),
  storage: LiteSkuStorageInformationSchema.optional(),
  careAndUse: LiteSkuCareAndUseSchema.optional(),
  leadTime: z.string(),
  isRangeProduct: z.boolean(),
  manufacture: LiteSkuManufactureSchema.optional(),
  attachments: z.array(LiteSkuAttachmentSchema),
  supplier: LiteSkuSupplierInformationSchema.optional(),
  buyerItemId: z.string().optional(),
});
