import { z } from "zod";

import { assertTypeEquality } from "@web/utils";

import { LiteConfiguration } from "src/typegens";

import { LiteAttentionInfoFleetsConfigEntrySchema } from "./LiteAttentionInfoFleetsConfigEntrySchema";
import { LiteBasketFormSchema } from "./LiteBasketFormSchema";
import { LiteCatalogConfigurationSchema } from "./LiteCatalogConfigurationSchema";
import { LiteFleetSchema } from "./LiteFleetSchema";
import { LiteInvoiceAccountSchema } from "./LiteInvoiceAccountSchema";
import { LitePortConfigurationSchema } from "./LitePortConfigurationSchema";
import { LitePortSchema } from "./LitePortSchema";
import { LiteSupplierSchema } from "./LiteSupplierSchema";
import { LiteUnitOfMeasureSchema } from "./LiteUnitOfMeasureSchema";
import { LiteVesselSchema } from "./LiteVesselSchema";
import { LiteWarehouseSchema } from "./LiteWarehouseSchema";
import { OrderStocktakeTypeSchema } from "./OrderStocktakeTypeSchema";

export const LiteConfigurationSchema = z.object({
  vessel: LiteVesselSchema,
  fleet: LiteFleetSchema,
  ports: z.record(z.array(LitePortSchema)),
  unitOfMeasures: z.array(LiteUnitOfMeasureSchema),
  warehouses: z.array(LiteWarehouseSchema),
  invoiceAccounts: z.array(LiteInvoiceAccountSchema),
  portConfiguration: z.record(LitePortConfigurationSchema),
  suppliers: z.record(LiteSupplierSchema),
  basketForm: LiteBasketFormSchema.optional(),
  attentionInfo: z.array(LiteAttentionInfoFleetsConfigEntrySchema),
  orderTypes: z.array(LiteCatalogConfigurationSchema).optional(),
  availableStocktakeTypes: z.array(OrderStocktakeTypeSchema),
});

export type LocalLiteConfiguration = z.infer<typeof LiteConfigurationSchema>;

assertTypeEquality<LocalLiteConfiguration, LiteConfiguration, LocalLiteConfiguration>();
