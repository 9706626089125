import { useFlag } from "@unleash/proxy-client-react";

import { Paragraph } from "@web/ui";

import { useAppStateContext } from "../contexts/AppStateContext";
import RequestProductInline from "./RequestProductInline";

interface Props {
  title: string;
  subtitle: string;
}

const EmptyIndicator: React.FC<Props> = ({ title, subtitle }) => {
  const hasRfqFeature = useFlag("rfq");
  const [{ configuration }] = useAppStateContext();

  return (
    <div className="flex flex-grow justify-center items-center flex-col">
      <div className="text-center">
        <Paragraph size="200">{title}</Paragraph>
        <Paragraph size="300" className="mt-1">
          {subtitle}
        </Paragraph>
      </div>
      {hasRfqFeature &&
        configuration?.fleet.permissions.includes("CREATE_REQUISITION") &&
        configuration.fleet.allow.createRfq && (
          <div className="w-full my-4">
            <RequestProductInline />
          </div>
        )}
    </div>
  );
};

export default EmptyIndicator;
