import { KeyboardEvent, memo, useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { preprocessFormAmountValue } from "@web/utils";

import {
  LocalStocktakeReportForm,
  StocktakeReportItemMeasurementUnitFieldPath,
  StocktakeReportItemQuantityFieldPath,
  StocktakeReportItemSingleUnitGrossPriceFieldPath,
} from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";

import { AmountField } from "./AmountField";
import { useItemFormDetails } from "./useItemFormDetails";

type Props = {
  initialQuantity: number | null | undefined;
  initialSingleUnitGrossPriceAmount: number | null | undefined;
  initialMeasurementUnit: string;
  salesEntityQuantity: number;
  currencyCode: string;
  quantityFieldPath: StocktakeReportItemQuantityFieldPath;
  singleUnitGrossPriceFieldPath: StocktakeReportItemSingleUnitGrossPriceFieldPath;
  measurementUnitFieldPath: StocktakeReportItemMeasurementUnitFieldPath;
};

export const QuantityField = memo(
  ({
    initialQuantity,
    initialSingleUnitGrossPriceAmount,
    initialMeasurementUnit,
    salesEntityQuantity,
    currencyCode,
    quantityFieldPath,
    singleUnitGrossPriceFieldPath,
    measurementUnitFieldPath,
  }: Props) => {
    const { control, setValue } = useFormContext<LocalStocktakeReportForm>();

    const { modifiedQuantity } = useItemFormDetails({
      initialQuantity,
      initialSingleUnitGrossPriceAmount,
      initialMeasurementUnit,
      salesEntityQuantity,
      currencyCode,
      quantityFieldPath,
      singleUnitGrossPriceFieldPath,
      measurementUnitFieldPath,
    });

    const calculateNewQuantity = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) =>
        LocalStocktakeService.getNewQuantityFromKeyboardEvent(
          preprocessFormAmountValue(modifiedQuantity) || 0,
          event
        ),
      [modifiedQuantity]
    );

    const setFieldValue = useCallback(
      (newQuantity: number | undefined) => {
        setValue(quantityFieldPath, newQuantity);
      },
      [quantityFieldPath, setValue]
    );

    return (
      <Controller
        name={quantityFieldPath}
        control={control}
        render={({ field }) => (
          <AmountField
            {...field}
            className="text-center"
            aria-label="Item quantity form field (optional)"
            calculateNewValue={calculateNewQuantity}
            setNewValue={setFieldValue}
            placeholder="0.00"
          />
        )}
      />
    );
  }
);
QuantityField.displayName = "QuantityField";
